import { useDispatch, useSelector } from 'react-redux'
import { setIsBackgroundLoading, storeCurrentTab, AppTabs } from '../redux/reducers/tabs'
import { RootState } from '../redux/rootReducer'
import { navigate } from 'gatsby'

export function useNavigation() {
    const dispatch = useDispatch()
    const currentTab: AppTabs = useSelector<RootState>((s) => s.tabs.currentTab) as AppTabs
    const isBackgroundLoading: boolean = useSelector<RootState>(
        (s) => s.tabs.isBackgroundLoading
    ) as boolean
    return {
        currentTab,
        isBackgroundLoading,
        setCurrentTab: (newTab: AppTabs) => {
            dispatch(storeCurrentTab({ currentTab: newTab }))
            switch (newTab) {
                case AppTabs.Rule183:
                    navigate('/app/tabs/rule-183/')
                    break
                default:
                    navigate('/app/')
                    break
            }
        },
        setIsBackgroundLoading: (newState: boolean) => {
            dispatch(setIsBackgroundLoading({ isBackgroundLoading: newState }))
        },
    }
}
