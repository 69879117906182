import * as React from 'react'
import {
    CalendarOutlined,
    FundOutlined,
    SettingOutlined,
    CalendarFilled,
    SettingFilled,
    FundFilled,
} from '@ant-design/icons'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useNavigation } from '../actions/navigation'
import { AppTabs } from '../redux/reducers/tabs'
import { useSessionIfExists } from '../hooks/useAuth'

function a11yProps(index: AppTabs) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        sx: {
            fontSize: '14px',
            lineHeight: '20px',
            color: 'gray500',
            '&.Mui-selected': {
                background: '#f2f6f8',
            },
            textTransform: 'none',
        },
    }
}

function IconWrapper({ children }) {
    return (
        <Box
            sx={{
                mb: '8px',
            }}
        >
            {children}
        </Box>
    )
}

export default function () {
    const { currentTab, setCurrentTab } = useNavigation()
    const { session } = useSessionIfExists()
    return (
        <Box
            className="nav-footer"
            sx={{
                position: 'fixed',
                left: 0,
                bottom: 0,
                width: '100%',
                padding: 0,
                background: 'white',
            }}
        >
            <Tabs
                value={currentTab}
                onChange={(event: React.SyntheticEvent<Element, Event>, value: AppTabs) =>
                    setCurrentTab(value)
                }
                aria-label="subscription tabs"
                variant="fullWidth"
                sx={{ borderTop: '1px solid #14143C' }}
            >
                <Tab
                    value={AppTabs.Calendar}
                    label={
                        <IconWrapper>
                            {(currentTab === AppTabs.Calendar && (
                                <CalendarFilled style={{ color: '#14143C', fontSize: '24px' }} />
                            )) || (
                                <CalendarOutlined style={{ color: '#14143C', fontSize: '24px' }} />
                            )}
                        </IconWrapper>
                    }
                    {...a11yProps(AppTabs.Calendar)}
                />
                <Tab
                    value={AppTabs.Overview}
                    label={
                        <IconWrapper>
                            {(currentTab === AppTabs.Overview && (
                                <FundFilled style={{ color: '#14143C', fontSize: '24px' }} />
                            )) || <FundOutlined style={{ color: '#14143C', fontSize: '24px' }} />}
                        </IconWrapper>
                    }
                    {...a11yProps(AppTabs.Overview)}
                />
                {session?.user?.feature_flags &&
                    session.user.feature_flags?.split(' ').includes('rule183') && (
                        <Tab
                            value={AppTabs.Rule183}
                            label={
                                <IconWrapper>
                                    {(currentTab === AppTabs.Rule183 && (
                                        <FundFilled
                                            style={{ color: '#14143C', fontSize: '24px' }}
                                        />
                                    )) || (
                                        <FundOutlined
                                            style={{ color: '#14143C', fontSize: '24px' }}
                                        />
                                    )}
                                </IconWrapper>
                            }
                            {...a11yProps(AppTabs.Rule183)}
                        />
                    )}
                <Tab
                    value={AppTabs.Settings}
                    label={
                        <IconWrapper>
                            {(currentTab === AppTabs.Settings && (
                                <SettingFilled style={{ color: '#14143C', fontSize: '24px' }} />
                            )) || (
                                <SettingOutlined style={{ color: '#14143C', fontSize: '24px' }} />
                            )}
                        </IconWrapper>
                    }
                    {...a11yProps(AppTabs.Settings)}
                />
            </Tabs>
        </Box>
    )
}
